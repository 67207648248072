<template>
  <div v-if="$store.state.fastPermissoes.ativo == 'S' && ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.professor == 'S')">
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a                
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin/'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin/')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a                
                class="aluno_font_color"
                href="#"
              >Integrações da Plataforma</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-0">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 col-lg-12 fd-mode-adm-content-body"
              style="margin-left: 0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome-novo text-left">
                <h2 class="aluno_font_color mb-0"
                style="font-size: 48px; font-weight: 500;">
                  Integrações da Plataforma
                </h2>
                <img
                  :src="require('@/assets/images/separador.png')"
                >
              </section>
              <!-- /fd-app-welcome -->
              <!-- Integrações -->
              <section>
                <!-- Autenticação de Documentos -->
                <div class="fd-card-blue">
                  <h4>Autenticação de Documentos</h4>
                  <hr>
                  <div class="fd-card-blue-slots">
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/autentique-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/zapsign-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/clicksign-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/docsign-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-blue ml-1"><b-icon-power /> Instalar</a>
                    </div>
                  </div>
                </div>
                <!-- / Autenticação de Documentos -->
                <!-- Gerenciamento de Pagamentos -->
                <div class="fd-card-blue">
                  <h4>Gerenciamento de Pagamentos</h4>
                  <hr>
                  <div class="fd-card-blue-slots">
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/gerencianet-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/asaas-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/mercadopago-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-blue ml-2"><b-icon-power /> Instalar</a>
                    </div>
                  </div>
                </div>
                <!-- / Gerenciamento de Pagamentos -->
                <!-- Gerenciamento de Anúncios -->
                <div class="fd-card-blue">
                  <h4>Gerenciamento de Anúncios</h4>
                  <hr>
                  <div class="fd-card-blue-slots">
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/googleads-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/googleanalytics-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/googlegtm-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-blue ml-2"><b-icon-power /> Instalar</a>
                    </div>
                  </div>
                </div>
                <!-- / Gerenciamento de Anúncios -->
                <!-- Serviços de E-mail -->
                <div class="fd-card-blue">
                  <h4>Serviços de E-mail</h4>
                  <hr>
                  <div class="fd-card-blue-slots">
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/amazonses-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                  </div>
                </div>
                <!-- / Serviços de E-mail -->
                <!-- Gerenciamento de Relacionamento ao Cliente (CRM) -->
                <div class="fd-card-blue">
                  <h4>Gerenciamento de Relacionamento ao Cliente (CRM)</h4>
                  <hr>
                  <div class="fd-card-blue-slots">
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/sprinthub-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                    <div class="fd-card-blue-slot ml-5 mr-auto">
                      <img :src="require('@/assets/images/integrações/piperun-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                  </div>
                </div>
                <!-- / Gerenciamento de Relacionamento ao Cliente (CRM) -->
                <!-- WhatsApp -->
                <div class="fd-card-blue">
                  <h4>WhatsApp</h4>
                  <hr>
                  <div class="fd-card-blue-slots">
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/whatsapi-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                    <div class="fd-card-blue-slot ml-5 mr-auto">
                      <img :src="require('@/assets/images/integrações/webwhatsapi-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                  </div>
                </div>
                <!-- / WhatsApp -->
                <!-- Videoconferência -->
                <div class="fd-card-blue">
                  <h4>Videoconferência</h4>
                  <hr>
                  <div class="fd-card-blue-slots">
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/googlemeet-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/zoom-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-outline-blue ml-1"><b-icon-gear /> Configurar</a>
                    </div>
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/fastlive-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-blue ml-2"><b-icon-power /> Instalar</a>
                    </div>
                    <div class="fd-card-blue-slot">
                      <img :src="require('@/assets/images/integrações/teams-logo.png')">
                      <a href="" @click.prevent class="btn-novo btn-blue ml-2"><b-icon-power /> Instalar</a>
                    </div>
                  </div>
                </div>
                <!-- / Videoconferência -->
              </section>
              <!-- / Integrações -->

              <div class="mt-4 mb-5">
                <a
                  class="btn-novo btn-red-hollow-novo"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left /> Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import imageSiteMatrizIcon from "@/assets/images/app/matriz-icon.png";
import imageSiteCursosIcon from "@/assets/images/app/cursos-icon.png";
import imageSiteUsuariosIcon from "@/assets/images/app/usuarios-icon.png";
import imageSitePersonalizarIcon from "@/assets/images/app/personalizar-icon.png";
import imageSiteOndemandIcon from "@/assets/images/app/ondemand-icon.png";
import imageSiteMuralIcon from "@/assets/images/app/mural-icon.png";
import imageSiteFinanceiro from "@/assets/images/app/financeiro-icon.png";

export default {
  name: "HomeInternoAdmin",

  mixins: [methods],
  data: function () {
    return {
      fastTemplate: settings.fastTemplate,
      //ImageSiteFormEffect01,
      imageSiteMatrizIcon,
      imageSiteCursosIcon,
      imageSiteUsuariosIcon,
      imageSitePersonalizarIcon,
      imageSiteOndemandIcon,
      imageSiteMuralIcon,
      imageSiteFinanceiro,
    };
  },
  computed: {
    HomeInternoAdminMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAdminMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {          
          // Se foi personalizado aplica cor nos textos
          if (this.$store.state.fastPersonalizacao.aluno_font_color) {
            Array.from(document.querySelectorAll(".aluno_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.aluno_font_color));
          }
          // Se foi personalizado aplica cor nos textos dos menus
          if (this.$store.state.fastPersonalizacao.menu_font_color) {
            Array.from(document.querySelectorAll(".menu_font_color")).forEach((e) => (e.style.color = this.$store.state.fastPersonalizacao.menu_font_color));
          }
          this.$store.state.fastCarregando = false;          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {},
};
</script>

<style></style>
